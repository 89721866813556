import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useFetch from '../hooks/useFetch';
import {SERVICE_URL_STOCKS, SERVICE_URL_STOCKS_ADD_QTY } from '../config/constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function NewStock() {
    const navigate = useNavigate();
    const { response, error, loading } = useFetch(
        SERVICE_URL_STOCKS
    );
    const default_value = {
        id_stock: null,
        unit: 'KG',
        qty: 0,
        mrp: 0,
        price: 0
    };
    const [stockHistory, setStockHistory] = useState(default_value);

    if (!loading) {
        response.forEach(element => {
            element.label = element.stock_name;
        });
    }

    const updateData = (field, value) => {
        setStockHistory({ ...stockHistory, [field]: value });
    };

    const addStock = () => {
        let text = "Are you sure to add stock?";
        if(!stockHistory.id_stock || !stockHistory.qty || !stockHistory.mrp || !stockHistory.price) {
            return false;
        }

        if (window.confirm(text) === true) {
            const optionsParams = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(stockHistory)
            };
            fetch(SERVICE_URL_STOCKS_ADD_QTY, optionsParams)
                .then(async () => {
                    setStockHistory(default_value);
                    navigate('/admin/stocks');
                });
        }

    };
    return (
        <div>
            <div>
                <h3>New Stocks</h3>
                <div className='spacing'>{loading ? 'Loading...' : <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={response}
                    sx={{ width: 300 }}
                    onChange={(e, value) => updateData('id_stock', value.id)}
                    renderInput={(params) => <TextField {...params} label="Product" />}
                />}</div>
                <div className='spacing'>
                    <TextField label="Quantity" type="number" variant="outlined" onChange={(e) => updateData('qty', e.target.value)} value={stockHistory.qty} />
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Unit" value={stockHistory.unit}
                        onChange={(e, value) => updateData('unit', e.target.value)}>
                        <MenuItem value={'KG'}>KG</MenuItem>
                        <MenuItem value={'PIECE'}>PIECE</MenuItem>
                    </Select>
                </div>
                <div className='spacing'>
                    <TextField label="M.R.P" type="number" variant="outlined" onChange={(e) => updateData('mrp', e.target.value)} value={stockHistory.mrp} /> <span style={{ 'padding': '15px 5px', 'display': 'inline-block' }}>/ KG OR PIECE</span>
                </div>
                <div className='spacing'>
                    <TextField label="Selling Price" type="number" variant="outlined" onChange={(e) => updateData('price', e.target.value)} value={stockHistory.price} /> <span style={{ 'padding': '15px 5px', 'display': 'inline-block' }}>/ KG OR PIECE</span>
                </div>
                <div><Button variant="contained" onClick={addStock}>Add Stock</Button></div>
            </div>
        </div>
    );
}

export default NewStock;

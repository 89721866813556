import { Button, TextField } from '@mui/material';
import { Grid, Container } from '@mui/material';
import Item from '@mui/material/Grid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SERVICE_URL_LOGIN } from '../config/constants';

function Login() {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const doLogin = () => {
        const optionsParams = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name, password })
        };
        fetch(SERVICE_URL_LOGIN, optionsParams)
            .then(async (response) => {
                const data = await response.json();
                if(data.success) {
                    sessionStorage.setItem("adminLogin", "1");
                    navigate("/admin/stocks", {replace: true});   
                }
            });
    }



    return <>
        <Container fixed>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    
                </Grid>
                <Grid item xs={8}>
                    <Item>
                        <div>
                            <h3>Login</h3>
                            <p><TextField value={name} onChange={(e) => { setName(e.target.value) }} label="username" variant="outlined" /> </p>
                            <p><TextField value={password} onChange={(e) => setPassword(e.target.value)} label="password" type="password" /> </p>
                            <p><Button onClick={doLogin} variant="contained">Login</Button></p>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    
                </Grid>
            </Grid>
        </Container>

    </>;
}

export default Login;

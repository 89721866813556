
import { Grid, Container, Button } from '@mui/material';
import Item from '@mui/material/Grid';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

function AdminLayout() {
    const navigate = useNavigate();
    useEffect(() => {
        if (!sessionStorage.getItem("adminLogin")) {
            navigate("/login", { replace: true });
        }
    }, []);

    return (
        <div className="App">
            <Container fixed>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Item><h1 style={{ 'textTransform': 'capitalize' }}>CT stock management</h1></Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>
                            <Button variant="text" href="#/admin/stocks">Available Stocks</Button>
                            <Button variant="text" href="#/admin/new-stock">Add Stocks</Button>
                            <Button variant="text" href="#/admin/products">Stock Names</Button>
                            <Button variant="text" href="#/admin/orders-reports">Stock Sales Report</Button>
                            <Button variant="text" href="#/admin/orders">Stock Sales History</Button>
                            <Button variant="text" href="#/">Sale Stock</Button>
                            <Button variant="text" href="#/admin-godown/new-godown-product">Manage Godown Sale</Button>
                        </Item>
                    </Grid>
                </Grid>

                <Outlet />
            </Container>

        </div>
    );
}

export default AdminLayout;

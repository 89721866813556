import { Button } from '@mui/material';
import { Grid, Container } from '@mui/material';
import Item from '@mui/material/Grid';
import useFetch from '../../hooks/useFetch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SERVICE_URL_GODOWN_SALES, SERVICE_URL_GODOWN_SALE_DETAILS } from '../../config/constants';
import { getFormattedDate } from '../../utils/helper';
import OrderDetailsDialog from '../order-details-dialog';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


function Sales() {
    const [openDialog, setOpenDialog] = useState(false);
    const [searchOrder, setSearchOrder] = useState(null);
    const [orders, setOrders] = useState([]);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState({});

    const today = new Date();
    const todayMinusOneMonth = new Date();
    todayMinusOneMonth.setMonth(todayMinusOneMonth.getMonth() - 1);
    const [fromDate, setFromDate] = useState(
        dayjs(todayMinusOneMonth),
    );
    const [toDate, setToDate] = useState(
        dayjs(today),
    );

    const getOrders = () => {
        const formattedFrom = JSON.stringify(fromDate).substring(1, 11);
        const formattedTo = JSON.stringify(toDate).substring(1, 11);
        const optionsParams = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ fromDate: formattedFrom, toDate: formattedTo })
        };
        fetch(SERVICE_URL_GODOWN_SALES, optionsParams)
            .then(async (response) => {
                const data = await response.json();
                setOrders(data);
            });
    };

    useEffect(() => {
        getOrders();
    }, []);

    const viewOrderDetails = (details) => {
        const optionsParams = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            }
        };
        fetch(SERVICE_URL_GODOWN_SALE_DETAILS + details.id_order, optionsParams)
            .then(async (response) => {
                const data = await response.json();
                if (data) {
                    details.orderType = data[0].order_type;
                    details.orderId = data[0].id_order;
                    details.orderDate = data[0].created;
                    details.total = data[0].grand_total;
                    setSelectedOrderDetails(data);
                    setSelectedOrder(details);
                    setOpenDialog(true);
                }
            });

    };

    const handleClose = () => {
        setOpenDialog(false);
    };


    return <>
        <Container fixed>
            <div>
                <h3>Godown Sales</h3>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Item>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        label="From"
                                        inputFormat="MM/DD/YYYY"
                                        value={fromDate}
                                        onChange={(value) => setFromDate(value)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        label="To"
                                        inputFormat="MM/DD/YYYY"
                                        value={toDate}
                                        onChange={(value) => setToDate(value)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item>
                        <Button variant="contained" onClick={getOrders}>Filter</Button>
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item>
                        <TextField label="Sale #" type="number" variant="outlined" onChange={(e) => setSearchOrder(e.target.value)} value={searchOrder} />
                    </Item>
                </Grid>
                <Grid item xs={1}>
                    <Item>
                        <Button variant="contained" onClick={() => { viewOrderDetails({ id_order: searchOrder }) }}>View Sale</Button>
                    </Item>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        <div className='spacing'>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Sale ID</TableCell>
                                            <TableCell>Sale Type</TableCell>
                                            <TableCell align="right">Total</TableCell>
                                            <TableCell>Date of Sale</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders.length === 0 ? 'Loading...' : orders.map((row, index) => (
                                            <TableRow
                                                key={row.id_order}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{row.id_order}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.order_type}
                                                </TableCell>
                                                <TableCell align="right">{row.total}</TableCell>
                                                <TableCell>{getFormattedDate(row.created)}</TableCell>
                                                <TableCell align="right"><Button variant="contained" onClick={() => { viewOrderDetails(row) }}>View</Button></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </Container>
        <OrderDetailsDialog name={"Godown Sale"} open={openDialog} handleClose={handleClose} orders={selectedOrderDetails} orderDetails={selectedOrder} />
    </>;
}

export default Sales;

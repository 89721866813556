const URL = 'http://coffeetimestore.in/api/';
//const URL = 'http://localhost:8080/api/';
export const SERVICE_URL_STOCK_ADD_NAME = URL +"stocks/add-name";
export const SERVICE_URL_STOCK_UPDATE_NAME = URL +"stocks/update-name";

export const SERVICE_URL_STOCKS_ADD_QTY = URL +"stocks/add-stock";
export const SERVICE_URL_STOCKS_UPDATE_QTY = URL +"stocks/update-stock";
export const SERVICE_URL_STOCKS = URL +"stocks";
export const SERVICE_URL_LOGIN = URL +"login";
export const SERVICE_URL_NEW_ORDER = URL +"new-order";

export const SERVICE_URL_ORDERS = URL +"orders";
export const SERVICE_URL_ORDERS_REPORTS = URL +"orders-reports";
export const SERVICE_URL_ORDER_DETAILS = URL +"order/details/";

export const SERVICE_URL_REPORTS = URL +"reports";

export const SERVICE_URL_GODOWN_ADD_NAME = URL +"godown/add-product-name";
export const SERVICE_URL_GODOWN_UPDATE_NAME = URL +"godown/update-product-name";
export const SERVICE_URL_GODOWN = URL +"godown/products";
export const SERVICE_URL_GODOWN_NEW_SALE = URL +"godown/new-sale";
export const SERVICE_URL_GODOWN_SALES = URL +"godown/sales";
export const SERVICE_URL_GODOWN_SALES_REPORTS = URL +"godown/sales-reports";
export const SERVICE_URL_GODOWN_SALE_DETAILS = URL +"godown/sale/details/";
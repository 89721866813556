import { Button } from '@mui/material';
import { Grid, Container } from '@mui/material';
import Item from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SERVICE_URL_REPORTS } from '../config/constants';
import { getFormattedDate } from '../utils/helper';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import * as agCharts from 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';

function Dashboard() {
    let totalStockEntry = 0;
    let totalSales = 0;
    const [stockEntries, setStockEntries] = useState([]);
    const [sales, setSales] = useState([]);
    const [stockChart, setStockChart] = useState(null);
    const [salesChart, setSalesChart] = useState(null);

    const today = new Date();
    const todayMinusOneMonth = new Date();
    todayMinusOneMonth.setMonth(todayMinusOneMonth.getMonth() - 1);
    const [fromDate, setFromDate] = useState(
        dayjs(todayMinusOneMonth),
    );
    const [toDate, setToDate] = useState(
        dayjs(today),
    );

    const getOrders = () => {
        const formattedFrom = JSON.stringify(fromDate).substring(1, 11);
        const formattedTo = JSON.stringify(toDate).substring(1, 11);
        const optionsParams = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ fromDate: formattedFrom, toDate: formattedTo })
        };
        fetch(SERVICE_URL_REPORTS, optionsParams)
            .then(async (response) => {
                const data = await response.json();
                setStockEntries(data[0]);
                setSales(data[1]);
                setStockChart({
                    options: {
                        data: data[2],
                        series: [
                            {
                                type: 'pie',
                                angleKey: 'value',
                                calloutLabelKey: 'stock_name',
                                sectorLabelKey: 'value',
                                sectorLabel: {
                                    color: 'white',
                                    fontWeight: 'bold',
                                },
                            },
                        ],
                    },
                });
                setSalesChart({
                    options: {
                        data: data[3],
                        series: [
                            {
                                type: 'pie',
                                angleKey: 'value',
                                calloutLabelKey: 'order_type',
                                sectorLabelKey: 'value',
                                sectorLabel: {
                                    color: 'white',
                                    fontWeight: 'bold',
                                },
                            },
                        ],
                    },
                });
            });
    };

    useEffect(() => {
        getOrders();
    }, []);

    return <>
        <Container fixed>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Item>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        label="From"
                                        inputFormat="MM/DD/YYYY"
                                        value={fromDate}
                                        onChange={(value) => setFromDate(value)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        label="To"
                                        inputFormat="MM/DD/YYYY"
                                        value={toDate}
                                        onChange={(value) => setToDate(value)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item>
                        <Button variant="contained" onClick={getOrders}>Filter</Button>
                    </Item>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Item>
                        <h2>Stock Entry</h2>
                        <div>
                            {stockChart && <AgChartsReact options={stockChart.options} />}
                        </div>
                        <div className='spacing'>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Stock Name</TableCell>
                                            <TableCell>Date of Order</TableCell>
                                            <TableCell align="right">Qty (UNIT)</TableCell>
                                            <TableCell align="right">M.R.P</TableCell>
                                            <TableCell align="right">Total M.R.P</TableCell>
                                            <TableCell align="right">Selling Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stockEntries.length === 0 ? 'Loading...' : stockEntries.map((stockEntry) => (

                                            <TableRow
                                                key={stockEntry.id_stock_history}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{stockEntry.stock_name}</TableCell>
                                                <TableCell>{getFormattedDate(stockEntry.created)}</TableCell>
                                                <TableCell align="right">{stockEntry.qty} ({stockEntry.unit})</TableCell>
                                                <TableCell align="right">{stockEntry.mrp}</TableCell>
                                                <TableCell align="right" data-total={totalStockEntry += parseInt(stockEntry.qty) * parseFloat(stockEntry.mrp)}>{parseInt(stockEntry.qty) * parseFloat(stockEntry.mrp)}</TableCell>
                                                <TableCell align="right">{stockEntry.price}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right" colSpan={2}><b>Total M.R.P</b></TableCell>
                                            <TableCell align="right"><b>{totalStockEntry}</b></TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>
                        <h2>Sales</h2>
                        <div>
                            {salesChart && <AgChartsReact options={salesChart.options} />}
                        </div>
                        <div className='spacing'>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Order ID</TableCell>
                                            <TableCell>Order Type</TableCell>
                                            <TableCell align="right">Total</TableCell>
                                            <TableCell>Date of Order</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sales.length === 0 ? 'Loading...' : sales.map((row) => (
                                            <TableRow
                                                key={row.id_order}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{row.id_order}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.order_type}
                                                </TableCell>
                                                <TableCell align="right" data-total={totalSales += parseFloat(row.total)}>{row.total}</TableCell>
                                                <TableCell>{getFormattedDate(row.created)}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell align="right" colSpan={2}><b>Total Sales</b></TableCell>
                                            <TableCell align="right"><b>{totalSales}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </Container>
    </>;
}

export default Dashboard;

import { Button } from '@mui/material';
import { Grid, Container } from '@mui/material';
import Item from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import OrderDetails from './common/order-details';

function SaleSuccess() {
    const orders = JSON.parse(sessionStorage.getItem("orders"));
    const orderDetails = JSON.parse(sessionStorage.getItem("orderDetails"));
    const navigate = useNavigate();

    const gotoNewOrder = () => {
        sessionStorage.removeItem("orders");
        sessionStorage.removeItem("orderDetails");
        navigate("/sale-godown-product", { replace: true });
    };


    return <>
        <OrderDetails orders={orders} orderDetails={orderDetails} name={"Godown Sale"} />
        <Container fixed>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Item>
                        <div><Button onClick={gotoNewOrder} variant="contained">New Sale</Button></div>
                    </Item>
                </Grid>
            </Grid>
        </Container>

    </>;
}

export default SaleSuccess;

import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Route,
  Routes,
  HashRouter,
} from "react-router-dom";
import App from './App';
import AdminLayout from './admin/admin-layout';
import Stocks from './admin/stocks';
import NewStock from './admin/new-stock';
import Products from './admin/products';
import reportWebVitals from './reportWebVitals';
import Login from './admin/login';
import './index.css'
import OrderSuccess from './order-success';
import Orders from './admin/orders';
import OrdersReports from './admin/orders-reports';
import Dashboard from './admin/dashboard';
import NewGodownProduct from './admin/godown/new-godown-product';
import AdminGodownLayout from './admin/godown/admin-godown-layout';
import SaleProduct from './sale-product';
import SaleSuccess from './sale-success';
import Sales from './admin/godown/sales';
import SalesReports from './admin/godown/sales-reports';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/sale-godown-product' element={<SaleProduct />} />
        <Route path='/login' element={<Login />} />
        <Route path='/order-success' element={<OrderSuccess />} />
        <Route path='/sale-success' element={<SaleSuccess />} />
        <Route path='admin' element={<AdminLayout />}>
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='orders' element={<Orders />} />
          <Route path='orders-reports' element={<OrdersReports />} />
          <Route path='stocks' element={<Stocks />} />
          <Route path='products' element={<Products />} />
          <Route path='new-stock' element={<NewStock />} />
        </Route>
        <Route path='admin-godown' element={<AdminGodownLayout/>}> 
          <Route path='new-godown-product' element={<NewGodownProduct />} />
          <Route path='sales' element={<Sales />} />
          <Route path='sales-reports' element={<SalesReports />} />
        </Route>
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Button, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SERVICE_URL_STOCKS, SERVICE_URL_STOCK_ADD_NAME, SERVICE_URL_STOCK_UPDATE_NAME } from '../config/constants';
import useFetch from "../hooks/useFetch";
import { getCurrentTime } from '../utils/helper';

function Products() {
    const [reloadTime, setReload] = useState(getCurrentTime());
    const [name1, setName1] = useState("");
    const [name2, setName2] = useState("");
    const { response, error, loading } = useFetch(
        SERVICE_URL_STOCKS, { query: { time: reloadTime } }
    );
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name1', headerName: 'Name in English or Tanglish', width: 200, editable: true },
        { field: 'name2', headerName: 'Name in Tamil', width: 200, editable: true }
    ];


    const addProduct = () => {
        const optionsParams = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name1, name2 })
        };
        fetch(SERVICE_URL_STOCK_ADD_NAME, optionsParams)
            .then(async () => {
                setName1('');
                setName2('');
                setReload(getCurrentTime())
            });
    }

    const onRowEditStop = (data) => {
        const optionsParams = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data.row)
        };
        fetch(SERVICE_URL_STOCK_UPDATE_NAME, optionsParams)
            .then(async () => {

            });
    }

    return <>
        <div>
            <h3>New Product</h3>
            <div className='spacing'><TextField fullWidth value={name1} onChange={(e) => { setName1(e.target.value) }} label="Name in English or Tanglish" variant="outlined" /> </div>
            <div className='spacing'><TextField fullWidth value={name2} onChange={(e) => setName2(e.target.value)} label="Name in Tamil" variant="outlined" /></div>
            <div><a href="http://www.tamiltyping.in/" target="_blank">http://www.tamiltyping.in/</a></div>
            <div style={{ paddingTop: '10px' }}><Button onClick={addProduct} variant="contained">Add</Button></div>
        </div>
        <div style={{ height: 400, width: '100%', paddingTop: '10px' }}>
            {loading ? 'Loading...' : <DataGrid
                editMode={'row'}
                rows={response}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                onRowEditStop={onRowEditStop}
            />}

        </div>
    </>;
}

export default Products;

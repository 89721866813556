import { Button } from '@mui/material';
import { Grid, Container } from '@mui/material';
import Item from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SERVICE_URL_ORDERS_REPORTS } from '../config/constants';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


function OrdersReports() {
    const [totalAmount, setTotalAmount] = useState(0);
    const [orders, setOrders] = useState([]);

    const today = new Date();
    const todayMinusOneMonth = new Date();
    todayMinusOneMonth.setMonth(todayMinusOneMonth.getMonth() - 1);
    const [fromDate, setFromDate] = useState(
        dayjs(todayMinusOneMonth),
    );
    const [toDate, setToDate] = useState(
        dayjs(today),
    );
    const [orderType, setOrderType] = useState("godown_bakery");

    const getOrders = () => {
        const formattedFrom = JSON.stringify(fromDate).substring(1, 11);
        const formattedTo = JSON.stringify(toDate).substring(1, 11);
        const optionsParams = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ fromDate: formattedFrom, toDate: formattedTo, orderType: orderType})
        };
        fetch(SERVICE_URL_ORDERS_REPORTS, optionsParams)
            .then(async (response) => {
                const data = await response.json();
                setOrders(data);
                let grandTotalValue = 0;
                data.forEach(element => {
                    grandTotalValue += parseFloat(element.total_qty_price);
                });
                setTotalAmount(grandTotalValue);
            });
    };

    useEffect(() => {
        getOrders();
    }, []);

    return <>
        <Container fixed>
            <div>
                <h3>Orders - Reports</h3>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Item>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        label="From"
                                        inputFormat="MM/DD/YYYY"
                                        value={fromDate}
                                        onChange={(value) => setFromDate(value)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        label="To"
                                        inputFormat="MM/DD/YYYY"
                                        value={toDate}
                                        onChange={(value) => setToDate(value)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={3}>
                    <Item>
                        <div>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Order Type" value={orderType}
                            onChange={(e) => setOrderType(e.target.value)}>
                            <MenuItem value={'ct_shop'}>CT Shop</MenuItem>
                            <MenuItem value={'godown_spicy_sweet'}>godown (காரம் & இனிப்பு)</MenuItem>
                            <MenuItem value={'godown_bakery'}>godown (bakery)</MenuItem>
                            <MenuItem value={'kitchen'}>kitchen</MenuItem>
                        </Select>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={2}>
                    <Item>
                        <Button variant="contained" onClick={getOrders}>Filter</Button>
                    </Item>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        <div className='spacing'>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Stock</TableCell>
                                            <TableCell align="right">Total Qty</TableCell>
                                            <TableCell align="right">Total Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders.length === 0 ? 'Loading...' : orders.map((row, index) => (
                                            <TableRow
                                                key={row.id_order}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{row.stock_name}</TableCell>
                                                <TableCell align="right">{row.total_qty} {row.unit}</TableCell>
                                                <TableCell align="right">{row.total_qty_price}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">Grand Total: <b>{totalAmount}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </Container>
    </>;
}

export default OrdersReports;

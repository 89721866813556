import { Grid, Container } from '@mui/material';
import Item from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getFormattedDate } from '../utils/helper';
import PrintIcon from '@mui/icons-material/Print';

function OrderDetails({ orders, orderDetails, name }) {

    return <>
        <Container fixed>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Item>
                        <h3>{name ? name : "Order"} Details <span style={{ verticalAlign: "middle", padding: "0 0 0 15px", cursor: "pointer" }} onClick={window.print}><PrintIcon /></span></h3>
                        <div>Date: {getFormattedDate(orderDetails.orderDate)}</div>

                    </Item>
                    <Item>
                        <div className='spacing'>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Item No</TableCell>
                                            <TableCell>Item Name</TableCell>
                                            <TableCell align="right">Qty</TableCell>
                                            <TableCell align="right">Price</TableCell>
                                            <TableCell align="right">Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders.map((row, index) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.stock_name}
                                                </TableCell>
                                                <TableCell align="right">{row.qty} {row.unit}</TableCell>
                                                <TableCell align="right">{row.price}</TableCell>
                                                <TableCell align="right">{row.total}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell component="th" colSpan={2} scope="row">Order #{orderDetails.orderId}</TableCell>
                                            <TableCell colSpan={2} align="right"><b>Total Amount:</b></TableCell>
                                            <TableCell align="right"><b>{orderDetails.total}</b></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Item>
                    <Item>
                        <div className='spacing'>
                            <span>Sale For: {orderDetails.orderType}</span>
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </Container>

    </>;
}

export default OrderDetails;

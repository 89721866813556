import { Button, TextField, Autocomplete } from '@mui/material';
import { Grid, Container } from '@mui/material';
import Item from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { SERVICE_URL_NEW_ORDER, SERVICE_URL_STOCKS } from './config/constants';
import useFetch from './hooks/useFetch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function App() {
  const { response, error, loading } = useFetch(
    SERVICE_URL_STOCKS
  );

  if (!loading) {
    response.forEach(element => {
      element.label = element.stock_name;
    });
  }
  const [selectedProduct, setSelectedProduct] = useState({});
  const [qty, setQty] = useState(0);
  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState({
    total: 0,
    orderType: 'bakery'
  });

  const navigate = useNavigate();

  const doOrder = () => {
    if(orders.length === 0 || !orderDetails) {
      return;
    }

    const optionsParams = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orders, orderDetails })
    };
    fetch(SERVICE_URL_NEW_ORDER, optionsParams)
      .then(async (response) => {
        const data = await response.json();
        if (data.success) {
          orderDetails.orderDate = new Date();
          orderDetails.orderId = data.orderId;
          sessionStorage.setItem("orders", JSON.stringify(orders));
          sessionStorage.setItem("orderDetails", JSON.stringify(orderDetails));
          navigate("/order-success", { replace: true });
        }
      });
  }

  const updateDetails = (stock) => {
    if (!stock) {
      stock = {};
    }
    setSelectedProduct(stock);
  };

  const doAdd = () => {
    let filtered = orders.filter(order => {
      return order.id === selectedProduct.id;
    });

    if (!qty || filtered.length || !selectedProduct.available_stock || parseInt(qty) > parseInt(selectedProduct.available_stock)) {
      return;
    }

    let copy = [...orders];
    copy = [...copy, {
      stock_name: selectedProduct.stock_name,
      id: selectedProduct.id,
      qty: qty,
      unit: selectedProduct.unit,
      price: selectedProduct.price,
      total: parseInt(qty) * parseFloat(selectedProduct.price)
    }];
    setQty(0);
    setOrders(copy);
  };

  useEffect(() => {
    let totalOrderAmount = 0;
    orders.forEach(order => {
      totalOrderAmount += order.total;
    });
    updateOrderDetails('total', totalOrderAmount);
  }, [orders]);


  const deleteOrder = (id) => {
    let filtered = orders.filter(order => {
      return order.id !== id;
    });
    setOrders(filtered);
  };

  const updateOrderDetails = (field, value) => {
    setOrderDetails({ ...orderDetails, [field]: value });
  };

  return <>
    <Container fixed>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item style={{textAlign: "right"}}><Button variant="text" href="#/admin/stocks">Admin</Button></Item>
          <Item>
            <div>
              <h3>Order</h3>
              <div>
                {loading ? 'Loading...' : <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={response}
                  sx={{ width: 300 }}
                  onChange={(e, value) => updateDetails(value)}
                  renderInput={(params) => <TextField {...params} label="Product" />}
                />}
              </div>
              <div className='spacing'>Available Stock: {selectedProduct.available_stock}</div>
              <div className='spacing'><TextField value={qty} onChange={(e) => setQty(e.target.value)} label="Quantity" type="number" />
                <span style={{ 'padding': '19px 10px', 'display': 'inline-table' }}>{selectedProduct.unit}</span></div>
              <div><Button onClick={doAdd} variant="contained">Add</Button></div>
            </div>
          </Item>
          <Item>
            <div className='spacing'>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item No</TableCell>
                      <TableCell>Item Name</TableCell>
                      <TableCell align="right">Qty</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Total</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((row, index) => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          {row.stock_name}
                        </TableCell>
                        <TableCell align="right">{row.qty} {row.unit}</TableCell>
                        <TableCell align="right">{row.price}</TableCell>
                        <TableCell align="right">{row.total}</TableCell>
                        <TableCell align="right"><Button variant="contained" onClick={() => { deleteOrder(row.id) }}>X</Button></TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="right" colSpan={4}><b>Total Amount:</b></TableCell>
                      <TableCell align="right"><b>{orderDetails.total}</b></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Item>
          <Item>
            <div className='spacing'>
              <span>Sale For: </span>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Unit" value={orderDetails.orderType}
                onChange={(e, value) => updateOrderDetails('orderType', e.target.value)}>
                <MenuItem value={'ct_shop'}>CT Shop</MenuItem>
                <MenuItem value={'godown_spicy_sweet'}>godown (காரம் & இனிப்பு)</MenuItem>
                <MenuItem value={'godown_bakery'}>godown (bakery)</MenuItem>
                <MenuItem value={'kitchen'}>kitchen</MenuItem>
              </Select>
            </div>
            <div className='spacing'><Button onClick={doOrder} disabled={orders.length === 0} variant="contained">Confirm the Order</Button></div>
          </Item>
        </Grid>
      </Grid>
    </Container>

  </>;
}

export default App;

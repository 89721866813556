
import { Grid, Container, Button } from '@mui/material';
import Item from '@mui/material/Grid';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

function AdminGodownLayout() {
    const navigate = useNavigate();
    useEffect(() => {
        if (!sessionStorage.getItem("adminLogin")) {
            navigate("/login", { replace: true });
        }
    }, []);

    return (
        <div className="App">
            <Container fixed>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Item><h1 style={{ 'textTransform': 'capitalize' }}>CT stock management</h1></Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>
                            <Button variant="text" href="#/admin-godown/new-godown-product">Godown Products</Button>
                            <Button variant="text" href="#/admin-godown/sales-reports">Godown Sales Report</Button>
                            <Button variant="text" href="#/admin-godown/sales">Godown Sales History</Button>
                            <Button variant="text" href="#/sale-godown-product">Sale Godown Products</Button>
                            <Button variant="text" href="#/admin/stocks">Manage Stocks</Button>
                        </Item>
                    </Grid>
                </Grid>
                <Outlet />
            </Container>

        </div>
    );
}

export default AdminGodownLayout;

import { DataGrid } from '@mui/x-data-grid';
import { SERVICE_URL_STOCKS, SERVICE_URL_STOCKS_UPDATE_QTY } from '../config/constants';
import useFetch from '../hooks/useFetch';

function Stocks() {
    const { response, error, loading } = useFetch(
        SERVICE_URL_STOCKS
    );
    const columns = [
        { field: 'stock_name', headerName: 'Name', width: 200  },
        { field: 'available_stock', headerName: 'Available Stock', width: 200, editable: true },
        { field: 'unit', headerName: 'Unit', width: 200 },
        { field: 'mrp', headerName: 'M.R.P', width: 200, editable: true },
        { field: 'price', headerName: 'Selling Price', width: 200, editable: true },
        
    ];

    const onRowEditStop = (data) => {
        const optionsParams = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data.row)
        };
        fetch(SERVICE_URL_STOCKS_UPDATE_QTY, optionsParams)
            .then(async () => {
                
            });
    };

    return (
        <div className="App">
            <h3>Stocks</h3>
            <div style={{ height: 400, width: '100%' }}>
                {loading? 'Loading...': <DataGrid
                    editMode={'row'}
                    rows={response}
                    columns={columns}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    onRowEditStop={onRowEditStop}
                />}
            </div>
        </div>
    );
}

export default Stocks;
